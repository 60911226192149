// 1. File Paths
// Usage - e.g. url('#{$image-directory}...')
// --------------
$public-path: "../";
$image-directory: "../../../images/";
$font-directory: "../fonts/";

// 2. Width
// --------------
$global-width: 1200px;

// 3. Fonts
// --------------
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,900&display=swap");
$primary-font: 'Lato', sans-serif;
//$secondary-font: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;

// 4. Colours
// --------------
/// Colors used for buttons, callouts, links, etc. There must always be a color called `primary`.
$primary-colour: #2a3473;
$secondary-colour: #9ec441;
$tertiary-colour: #616161;
/// @type Map
$foundation-palette: (
  primary: $primary-colour,
  secondary: $secondary-colour,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
) !default;
$light-grey: #999!default;
$lighter-grey : #e2e2e2;
$medium-grey: #cacaca !default;
$dark-grey: #707070 !default;
$dark_green: #7ba613;
$black: #0a0a0a !default;
$white: #ffffff !default;
$off-white: #edf0ef !default;

$body-background: $white !default;
$body-font-color: $black !default;

// 5. Base
// -----------
$base-font-family: $primary-font !default;
$base-font-size: 14px !default;
$base-line-height: 150% !default;
$base-font-color: $black !default;
$base-font-weight: 400 !default;
$base-background-color: $white !default;

$small_font: 0.875rem;
$large_font: 2rem;

// 6. Headings
// --------------
$base-heading-font-family: $primary-font !default;
$base-heading-line-height: $base-line-height !default;
$base-heading-font-weight: $base-font-weight !default;
$base-h1-font-size: 2rem !default;
$base-h2-font-size: 1.8rem !default;
$base-h3-font-size: 1.6rem !default;
$base-h4-font-size: 1.4rem !default;
$base-h5-font-size: 1.4rem !default;
$base-h6-font-size: 1.4rem !default;

// 7. Breakpoints
// --------------
//  Mixin scss classes e.g. @include bp(mlarge)
//  & Foundation breakpoint html class names e.g. class="small-6 xlarge-12"
$breakpoints: (
  small: 0,
  smedium: 480px,
  medium: 640px,
  mlarge: 768px,
  large: 960px,
  xlarge: 1024px,
  xxlarge: $global-width,
  xxxlarge: 1800px,
);
$breakpoint-classes: (small smedium medium mlarge large xlarge xxlarge xxlarge);

// 8. The Grid
// -----------
$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 20px,
  medium: 30px,
);
$grid-column-align-edge: true;
$grid-column-alias: "columns";
$block-grid-max: 8;

$grid-gutter: 16px !default;
$global-font-size: 100% !default;
$global-width: rem-calc(1200) !default;
$global-lineheight: 1.5 !default;

// 9. Global
// --------------
$global-lineheight: 1.5;
$body-safe-padding: false;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;
$print-hrefs: true;
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);

// 10. Transition
// --------------
$global-transition: 400ms ease-in-out;
