/* Header
================================================== */

header {
  background:$primary-colour; 
  height:92px;
  position:absolute; 
  width:100%; 
  top:0; 
  left:0; 
  padding:10px 0;
  @include bp_max(large) {height:auto;}}
.logo { 
p {text-align:center; margin:0;} 
img { width:400px; max-width:400px;
  @include bp_max(large) {width:420px;}
  }}
.customlogo p {margin:0;}
.sliderhead soan {font-weight:800;}
.iframe-container{position: relative; width: 100%; padding-bottom: 56.25%; height: 0;}
.iframe-container iframe{position: absolute;top:0;left: 0;width: 100%;height: 100%;}

.moduletablectas2 {padding:10px 10px 0 0; text-align:right;
  @include bp_max(large) {padding:0;}}
a.brochure-link, a.quote-link { display:none;
  @include bp(large){ display:inline-block !important;}}
a.phone-link {color:$white; font-weight:600; line-height:31px;
  //@include bp_max(medium) {float:left;}        
}
a.phone-link:hover {color:$secondary-colour;}

.social {
    display: flex;
    z-index: 50;
    justify-content:center;
    @include bp(medium) {
      justify-content: flex-end;
    
    }
    @include bp(mlarge) {
     // .va();
      position: fixed;
      right: 0;
      z-index: 50;
    }
  }
  .social-icons {
    padding: 16px 12px;
    color:$white;
    font-size:1.2em;
    text-align:center;
    background-color: rgba(42,52,115,0.5);
    a {
      padding: 10px;
      color:$white;
      font-size:1.4em;
      @include bp(medium) {
        background-color:none;
      }
      @include bp(mlarge) {
      	display: block;
        float:none;
        
      }
    }
    a:hover {
      color: $secondary-colour;
    }
  }


  .header {
    &__top {
      display:flex;
      flex-direction: column;
      @include bp(medium) {
        flex-direction:row;
      }
    }
  &_wrap {
    display:flex;
    justify-content:space-between;
    align-items:center;
    @include bp(medium) {
      justify-content: flex-end;
    }
  }
  }