/* Footer
================================================== */
footer {
  background:#aaa; 
  padding:32px 0; 
  color:$dark_grey;
  p, li, a {
    color:$dark_grey;
  }
  p {
    margin: 0;
  }
  .row {
    display: flex;
    align-items: center;
  }
  &.__ctas {
    @include bp_max(large) {
      display: none;
    }
  }
}
.moduletablelivebuzzlogo {
  text-align:center;
  p {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width:180px; 
    height:auto; 
    margin:0 0 -9px 4px;
    opacity:0.4;
  }
}

a.brochure-link, a.quote-link {background: #FFF; padding: 7px 6px 6px 10px; border-radius: 25px; float: right; margin: 0 0 0 20px; color:$primary-colour;}
a.quote-link {background:$secondary-colour !important;}
a.quote-link:hover, a.brochure-link:hover { background-color:$dark_green !important;}

