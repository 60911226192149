.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
  background: $primary-colour;
  border: 0;
  color: $white;
  font-size: 1rem;
  font-weight: normal;
  text-decoration: none;
  line-height: normal;
  font-family: $primary-font;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 0;
  @include transition($global-transition);
  -webkit-appearance: none;

  &:hover {
    color: $white;
    background: darken($primary-colour, 8%);
    border: 0;
  }
  &:active {
    border: 0;
  }
  &:visited {
    color: $white;
  }
}

.button {
  &--full-width {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
  }
  &--secondary {
    background: $secondary-colour;
    &:hover {
      background: darken($secondary-colour, 8%);
    }
  }
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
