/* Main
================================================== */
main {
  background: url("#{$image-directory}moseley_bg.jpg") no-repeat 0% 0%;
  background-size: 100%;
  div.main-content {
    color: $tertiary-colour;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+98 */
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 98%,
      rgba(255, 255, 255, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 98%,
      rgba(255, 255, 255, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 98%,
      rgba(255, 255, 255, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    @include bp(xxxlarge) {
      background: none;
    }
  }
}

.text {
  padding-top: 60px;
  background: rgba(255, 255, 255, 0.5);
  @include bp(xxxlarge) {
    background: rgba(255, 255, 255, 0.2);
  }
  @include bp_max(medium) {
    background: rgba(255, 255, 255, 0.7);
  }
  .right {
    margin-left: auto;
  }
}

/* Gallery
================================================== */
a.ba-filter,
a.ba-filter-active {
  padding: 7px 14px !important;
}

/* Heritage Section
================================================== */
.heritage {
  background: $primary-colour url("#{$image-directory}heritage.jpg") no-repeat
    0% 50%;
  padding: 40px 0;
  background-size: 50%;
  margin-top: 40px;
  p,
  li,
  h2,
  h3,
  h4 {
    color: $white;
  }
  img {
    width: 250px;
    height: auto;
    padding: 0 0 40px 20px;
    float: right;
  }
  @include bp(xxlarge) {
    padding: 20px 0;
  }
  @include bp_max(xlarge) {
    background-image: none;
  }
}

/* Testimonial Section
================================================== */
.testimonial {
  background: $lighter-grey url("#{$image-directory}quote_bg.jpg") no-repeat
    left bottom;
  padding: 40px 0;
  background-size: 30%;
}

.lnee ul.latestnews-items li p {
  text-align: left;
  background: none;
}

.logos,
.tagboard {
  margin-bottom: 30px;
  h3 {
    text-align: center;
  }
}
.tagboard {
  margin-top: 40px;
}
.lnee .newshead .picture img {
  margin-left: 40px;
  @include bp(mlarge) {
    margin-left: 0;
  }
}

#lnee_100 .newshead.picturetype {
  @include bp(mlarge) {
    max-width: 30% !important;
    float: right;
  }
  @include bp_max(medium) {
    max-width: 100% !important;
    float: right;
    width: 100% !important;
  }
}

#lnee_100 .newsinfo {
  @include bp_max(medium) {
    overflow: visible;
    padding-top: 20px;
    clear: both;
    background: none;

    .newsintro {
      blockquote p {
        font-size: 17px;
        line-height: 24px;
        color: #777;
        font-style: italic;
      }
    }
  }
}

/* Footer Top
================================================== */
.footer-top {
  background-color: $lighter-grey;
  padding: 20px 0 15px;
}
.moduletablefooterlogo {
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
  img {
    width: 80%;
    height: auto;
  }
  p {
    margin-bottom: 0;
  }
}
.moduletablefootermenu {
  text-align: center;
  @include bp_max(large) {
    padding: 40px 0;
    clear: both;
    display: block;
    overflow: hidden;
  }
  ul {
    width: 50%;
    margin: 0 auto;
  }
  li {
    width: 50%;
    list-style: none;
    float: left;
    display: block;
    margin: 0;
  }
}
.moduletableaccreditations {
  text-align: center;
  img {
    width: 28%;
    float: left;
    margin: 2%;
  }
}

/* Contact
================================================== */
.moduletablemap {
  padding-top: 74px;
  margin: 0 0 -5px;
  @include bp(xxlarge) {
    padding-top: 63px;
  }
  @include bp_max(medium) {
    padding-top: 149px;
  }
}

/* Product pages
================================================== */
.moduletableproductslist {
  padding: 20px;
  color: $white;
  background: rgba(42, 52, 115, 0.62);
  p,
  li,
  a,
  h2,
  h3,
  h4 {
    color: $white;
  }
  a:after {
    content: "  \f35a";
    font-family: "Font Awesome 5 Pro";
  }
}
.fitvids-container iframe {
  width: 100% !important;
  height: 354px !important;
}
