@charset "UTF-8";
/* MAIN LESS FILE FOR COMPILING
--------------------------------------------------------------*/
/*--------------------------------------------------------------
1.0 Settings
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,900&display=swap");
/*--------------------------------------------------------------
2.0 Base
--------------------------------------------------------------*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
}

.sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1rem;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* Balbooa Forms Component 
================================================== */
.ba-form-page .ba-form-field-item {
  margin: 0 0 1rem 0;
}
.ba-form-page fieldset {
  padding: 0;
  border: none;
}
.ba-form-page .ba-form-field-item .ba-form-products-cart .ba-form-product-quantity-cell input,
.ba-form-page .ba-form-page-navigation-title,
.ba-form-page .upload-file-btn,
.ba-form-page .upload-file-input,
.ba-form-page .ba-field-container select,
.ba-form-page .ba-field-container textarea[readonly],
.ba-form-page .ba-field-container textarea,
.ba-form-page .ba-field-container input[type=text],
.ba-form-page .ba-field-container input[type=number],
.ba-form-page .ba-field-container input[type=password],
.ba-form-page .ba-field-container input[type=email],
.ba-form-page .ba-field-container select option,
.ba-form-page .ba-form-field-item .ba-form-checkbox-wrapper .ba-checkbox-title {
  font-weight: normal;
  font-size: 1rem !important;
  color: #0a0a0a !important;
}
.ba-form-page .ba-form-acceptance-field .ba-field-container {
  margin-top: 2rem;
}
.ba-form-page .ba-form-submit-wrapper .ba-form-submit-btn-wrapper {
  width: auto !important;
}
.ba-form-page .ba-form-submit-field {
  margin-bottom: 0 !important;
}
.ba-form-page .ba-form-submit-btn {
  margin-top: 2rem;
  margin-bottom: 0;
}

.table-wrap {
  width: 100%;
  overflow-x: auto;
}

table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  border: 1px solid #ffffff;
}
table td,
table th {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
table td *,
table th * {
  margin: 0;
}
table th {
  background-color: #9ec441;
  font-size: 1.1rem;
}
table th * {
  color: #ffffff;
}

table tr:nth-child(2n) {
  background-color: #edf0ef;
}

img {
  max-width: 100%;
  height: auto;
}

body figure[data-wf-figure] {
  display: block;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 150%;
  color: #0a0a0a;
  font-weight: 400;
  background: #ffffff;
}

main,
details {
  display: block;
}

a {
  color: #9ec441;
  background-color: transparent;
  text-decoration: none;
  -webkit-transition: 400ms ease-in-out;
  -moz-transition: 400ms ease-in-out;
  -ms-transition: 400ms ease-in-out;
  -o-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out;
}
a:hover {
  color: #0a0a0a;
}

b,
.b,
strong,
.strong {
  font-weight: 700;
}

em,
.em {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

small,
.small {
  font-size: 0.8125rem;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

template,
[hidden] {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
  line-height: 150%;
  font-weight: 400;
  margin: 0.5rem 0;
}

h1,
.h1 {
  font-size: 2rem;
}

h2,
.h2 {
  font-size: 1.8rem;
}

h3,
.h3 {
  font-size: 1.6rem;
}

h4,
.h4 {
  font-size: 1.4rem;
}

h5,
.h5 {
  font-size: 1.4rem;
}

h6,
.h6 {
  font-size: 1.4rem;
}

.button,
button,
input[type=submit],
input[type=reset],
input[type=button] {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
  background: #2a3473;
  border: 0;
  color: #ffffff;
  font-size: 1rem;
  font-weight: normal;
  text-decoration: none;
  line-height: normal;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 0;
  -webkit-transition: 400ms ease-in-out;
  -moz-transition: 400ms ease-in-out;
  -ms-transition: 400ms ease-in-out;
  -o-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out;
  -webkit-appearance: none;
}
.button:hover,
button:hover,
input[type=submit]:hover,
input[type=reset]:hover,
input[type=button]:hover {
  color: #ffffff;
  background: #1f2655;
  border: 0;
}
.button:active,
button:active,
input[type=submit]:active,
input[type=reset]:active,
input[type=button]:active {
  border: 0;
}
.button:visited,
button:visited,
input[type=submit]:visited,
input[type=reset]:visited,
input[type=button]:visited {
  color: #ffffff;
}

.button--full-width {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}
.button--secondary {
  background: #9ec441;
}
.button--secondary:hover {
  background: #86a834;
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-0-5rem {
  padding-left: 0.5rem;
}

.padding-left-1rem {
  padding-left: 1rem;
}

.padding-left-1-5rem {
  padding-left: 1.5rem;
}

.padding-left-2rem {
  padding-left: 2rem;
}

.padding-left-3rem {
  padding-left: 3rem;
}

.padding-left-4rem {
  padding-left: 4rem;
}

.padding-left-5rem {
  padding-left: 5rem;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-0-25rem {
  padding-right: 0.25rem;
}

.padding-right-0-5rem {
  padding-right: 0.5rem;
}

.padding-right-1rem {
  padding-right: 1rem;
}

.padding-right-1-5rem {
  padding-right: 1.5rem;
}

.padding-right-2rem {
  padding-right: 2rem;
}

.padding-right-3rem {
  padding-right: 3rem;
}

.padding-right-4rem {
  padding-right: 4rem;
}

.padding-right-5rem {
  padding-right: 5rem;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-0-25rem {
  margin-left: 0.25rem;
}

.margin-left-0-5rem {
  margin-left: 0.5rem;
}

.margin-left-1rem {
  margin-left: 1rem;
}

.margin-left-1-5rem {
  margin-left: 1.5rem;
}

.margin-left-2rem {
  margin-left: 2rem;
}

.margin-left-3rem {
  margin-left: 3rem;
}

.margin-left-4rem {
  margin-left: 4rem;
}

.margin-left-5rem {
  margin-left: 5rem;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-right-0-25rem {
  margin-right: 0.25rem;
}

.margin-right-0-5rem {
  margin-right: 0.5rem;
}

.margin-right-1rem {
  margin-right: 1rem;
}

.margin-right-1-5rem {
  margin-right: 1.5rem;
}

.margin-right-2rem {
  margin-right: 2rem;
}

.margin-right-3rem {
  margin-right: 3rem;
}

.margin-right-4rem {
  margin-right: 4rem;
}

.margin-right-5rem {
  margin-right: 5rem;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-0-25rem {
  padding-top: 0.25rem;
}

.padding-top-0-5rem {
  padding-top: 0.5rem;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.padding-top-1-5rem {
  padding-top: 1.5rem;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.padding-top-3rem {
  padding-top: 3rem;
}

.padding-top-4rem {
  padding-top: 4rem;
}

.padding-top-5rem {
  padding-top: 5rem;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-0-25rem {
  padding-bottom: 0.25rem;
}

.padding-bottom-0-5rem {
  padding-bottom: 0.5rem;
}

.padding-bottom-1rem {
  padding-bottom: 1rem;
}

.padding-bottom-1-5rem {
  padding-bottom: 1.5rem;
}

.padding-bottom-2rem {
  padding-bottom: 2rem;
}

.padding-bottom-3rem {
  padding-bottom: 3rem;
}

.padding-bottom-4rem {
  padding-bottom: 4rem;
}

.padding-bottom-5rem {
  padding-bottom: 5rem;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-0-25rem {
  margin-top: 0.25rem;
}

.margin-top-0-5rem {
  margin-top: 0.5rem;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.margin-top-1-5rem {
  margin-top: 1.5rem;
}

.margin-top-2rem {
  margin-top: 2rem;
}

.margin-top-3rem {
  margin-top: 3rem;
}

.margin-top-4rem {
  margin-top: 4rem;
}

.margin-top-5rem {
  margin-top: 5rem;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-0-25rem {
  margin-bottom: 0.25rem;
}

.margin-bottom-0-5rem {
  margin-bottom: 0.5rem;
}

.margin-bottom-1rem {
  margin-bottom: 1rem;
}

.margin-bottom-1-5rem {
  margin-bottom: 1.5rem;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.margin-bottom-3rem {
  margin-bottom: 3rem;
}

.margin-bottom-4rem {
  margin-bottom: 4rem;
}

.margin-bottom-5rem {
  margin-bottom: 5rem;
}

.padding-0 {
  padding: 0;
}

.padding-0-25rem {
  padding: 0.25rem;
}

.padding-0-5rem {
  padding: 0.5rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-1-5rem {
  padding: 1.5rem;
}

.padding-2rem {
  padding: 2rem;
}

.padding-3rem {
  padding: 3rem;
}

.padding-4rem {
  padding: 4rem;
}

.padding-5rem {
  padding: 5rem;
}

.margin-0 {
  margin: 0;
}

.margin-0-25rem {
  margin: 0.25rem;
}

.margin-0-5rem {
  margin: 0.5rem;
}

.margin-1rem {
  margin: 1rem;
}

.margin-1-5rem {
  margin: 1.5rem;
}

.margin-2rem {
  margin: 2rem;
}

.margin-3rem {
  margin: 3rem;
}

.margin-4rem {
  margin: 4rem;
}

.margin-5rem {
  margin: 5rem;
}

.none {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-align-items-top {
  align-items: flex-start;
}

.flex-align-items-center {
  align-items: center;
}

.flex-align-items-bottom {
  align-items: flex-end;
}

.flex-justify-left {
  justify-content: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-right {
  justify-content: flex-end;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.no-select {
  user-select: none;
  cursor: default;
}

.font-100 {
  font-weight: 100;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-800 {
  font-weight: 800;
}

.font-900 {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/*--------------------------------------------------------------
3.0 Foundation
--------------------------------------------------------------*/
/**
 * Foundation for Sites
 * Version 6.7.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/* either include all foundation components…  //*/
/* or include specific foundation components //*/
/* include either foundation-grid… //*/
/* or foundation-flex-grid (but don't include both) //*/
.row {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 30em) {
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}
@media print, screen and (min-width: 40em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 48em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 60em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 75em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 75em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}
.row.expanded {
  max-width: none;
}
.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}
.row:not(.expanded) .row {
  max-width: none;
}
.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}
.row.is-collapse-child, .row.collapse > .column > .row, .row.collapse > .columns > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0;
}
@media print, screen and (min-width: 40em) {
  .column, .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row, .row.row.columns {
  float: none;
  display: block;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 {
  flex-wrap: wrap;
}
.small-up-1 > .column, .small-up-1 > .columns {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}
.small-up-2 > .column, .small-up-2 > .columns {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}
.small-up-3 > .column, .small-up-3 > .columns {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-up-4 {
  flex-wrap: wrap;
}
.small-up-4 > .column, .small-up-4 > .columns {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}
.small-up-5 > .column, .small-up-5 > .columns {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}
.small-up-6 > .column, .small-up-6 > .columns {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-up-7 {
  flex-wrap: wrap;
}
.small-up-7 > .column, .small-up-7 > .columns {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.small-up-8 {
  flex-wrap: wrap;
}
.small-up-8 > .column, .small-up-8 > .columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 30em) {
  .smedium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .smedium-offset-0 {
    margin-left: 0%;
  }
  .smedium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .smedium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .smedium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-offset-3 {
    margin-left: 25%;
  }
  .smedium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .smedium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .smedium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .smedium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .smedium-offset-6 {
    margin-left: 50%;
  }
  .smedium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .smedium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .smedium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .smedium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .smedium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .smedium-offset-9 {
    margin-left: 75%;
  }
  .smedium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .smedium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .smedium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .smedium-up-1 {
    flex-wrap: wrap;
  }
  .smedium-up-1 > .column, .smedium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-up-2 {
    flex-wrap: wrap;
  }
  .smedium-up-2 > .column, .smedium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-up-3 {
    flex-wrap: wrap;
  }
  .smedium-up-3 > .column, .smedium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-up-4 {
    flex-wrap: wrap;
  }
  .smedium-up-4 > .column, .smedium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-up-5 {
    flex-wrap: wrap;
  }
  .smedium-up-5 > .column, .smedium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .smedium-up-6 {
    flex-wrap: wrap;
  }
  .smedium-up-6 > .column, .smedium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-up-7 {
    flex-wrap: wrap;
  }
  .smedium-up-7 > .column, .smedium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .smedium-up-8 {
    flex-wrap: wrap;
  }
  .smedium-up-8 > .column, .smedium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 30em) and (min-width: 30em) {
  .smedium-expand {
    flex: 1 1 0px;
  }
}
.row.smedium-unstack > .column, .row.smedium-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 30em) {
  .row.smedium-unstack > .column, .row.smedium-unstack > .columns {
    flex: 1 1 0px;
  }
  .smedium-collapse > .column, .smedium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .smedium-uncollapse > .column, .smedium-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}
.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 40em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns {
    flex: 1 1 0px;
  }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 48em) {
  .mlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .mlarge-offset-0 {
    margin-left: 0%;
  }
  .mlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .mlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .mlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .mlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .mlarge-offset-3 {
    margin-left: 25%;
  }
  .mlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .mlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .mlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .mlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .mlarge-offset-6 {
    margin-left: 50%;
  }
  .mlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .mlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .mlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .mlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .mlarge-offset-9 {
    margin-left: 75%;
  }
  .mlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .mlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .mlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .mlarge-up-1 {
    flex-wrap: wrap;
  }
  .mlarge-up-1 > .column, .mlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mlarge-up-2 {
    flex-wrap: wrap;
  }
  .mlarge-up-2 > .column, .mlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mlarge-up-3 {
    flex-wrap: wrap;
  }
  .mlarge-up-3 > .column, .mlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .mlarge-up-4 {
    flex-wrap: wrap;
  }
  .mlarge-up-4 > .column, .mlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mlarge-up-5 {
    flex-wrap: wrap;
  }
  .mlarge-up-5 > .column, .mlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .mlarge-up-6 {
    flex-wrap: wrap;
  }
  .mlarge-up-6 > .column, .mlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .mlarge-up-7 {
    flex-wrap: wrap;
  }
  .mlarge-up-7 > .column, .mlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .mlarge-up-8 {
    flex-wrap: wrap;
  }
  .mlarge-up-8 > .column, .mlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 48em) and (min-width: 48em) {
  .mlarge-expand {
    flex: 1 1 0px;
  }
}
.row.mlarge-unstack > .column, .row.mlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 48em) {
  .row.mlarge-unstack > .column, .row.mlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .mlarge-collapse > .column, .mlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .mlarge-uncollapse > .column, .mlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 60em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 60em) and (min-width: 60em) {
  .large-expand {
    flex: 1 1 0px;
  }
}
.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 60em) {
  .row.large-unstack > .column, .row.large-unstack > .columns {
    flex: 1 1 0px;
  }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 64em) {
  .xlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xlarge-offset-0 {
    margin-left: 0%;
  }
  .xlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-offset-3 {
    margin-left: 25%;
  }
  .xlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xlarge-offset-6 {
    margin-left: 50%;
  }
  .xlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xlarge-offset-9 {
    margin-left: 75%;
  }
  .xlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xlarge-up-1 {
    flex-wrap: wrap;
  }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-up-2 {
    flex-wrap: wrap;
  }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-up-3 {
    flex-wrap: wrap;
  }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-up-4 {
    flex-wrap: wrap;
  }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-up-5 {
    flex-wrap: wrap;
  }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xlarge-up-6 {
    flex-wrap: wrap;
  }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-up-7 {
    flex-wrap: wrap;
  }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xlarge-up-8 {
    flex-wrap: wrap;
  }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 64em) and (min-width: 64em) {
  .xlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 64em) {
  .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 75em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 75em) and (min-width: 75em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 75em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 75em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 75em) and (min-width: 75em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 75em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.25rem;
}
.column-block > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 40em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------
4.0 Components
--------------------------------------------------------------*/
/* Main
================================================== */
main {
  background: url("../../../images/moseley_bg.jpg") no-repeat 0% 0%;
  background-size: 100%;
}
main div.main-content {
  color: #616161;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+98 */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 98%, rgb(255, 255, 255) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 98%, rgb(255, 255, 255) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 98%, rgb(255, 255, 255) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=0 ); /* IE6-9 */
}
@media (min-width: 1800px) {
  main div.main-content {
    background: none;
  }
}

.text {
  padding-top: 60px;
  background: rgba(255, 255, 255, 0.5);
}
@media (min-width: 1800px) {
  .text {
    background: rgba(255, 255, 255, 0.2);
  }
}
@media (max-width: 639px) {
  .text {
    background: rgba(255, 255, 255, 0.7);
  }
}
.text .right {
  margin-left: auto;
}

/* Gallery
================================================== */
a.ba-filter,
a.ba-filter-active {
  padding: 7px 14px !important;
}

/* Heritage Section
================================================== */
.heritage {
  background: #2a3473 url("../../../images/heritage.jpg") no-repeat 0% 50%;
  padding: 40px 0;
  background-size: 50%;
  margin-top: 40px;
}
.heritage p,
.heritage li,
.heritage h2,
.heritage h3,
.heritage h4 {
  color: #ffffff;
}
.heritage img {
  width: 250px;
  height: auto;
  padding: 0 0 40px 20px;
  float: right;
}
@media (min-width: 1200px) {
  .heritage {
    padding: 20px 0;
  }
}
@media (max-width: 1023px) {
  .heritage {
    background-image: none;
  }
}

/* Testimonial Section
================================================== */
.testimonial {
  background: #e2e2e2 url("../../../images/quote_bg.jpg") no-repeat left bottom;
  padding: 40px 0;
  background-size: 30%;
}

.lnee ul.latestnews-items li p {
  text-align: left;
  background: none;
}

.logos,
.tagboard {
  margin-bottom: 30px;
}
.logos h3,
.tagboard h3 {
  text-align: center;
}

.tagboard {
  margin-top: 40px;
}

.lnee .newshead .picture img {
  margin-left: 40px;
}
@media (min-width: 768px) {
  .lnee .newshead .picture img {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  #lnee_100 .newshead.picturetype {
    max-width: 30% !important;
    float: right;
  }
}
@media (max-width: 639px) {
  #lnee_100 .newshead.picturetype {
    max-width: 100% !important;
    float: right;
    width: 100% !important;
  }
}

@media (max-width: 639px) {
  #lnee_100 .newsinfo {
    overflow: visible;
    padding-top: 20px;
    clear: both;
    background: none;
  }
  #lnee_100 .newsinfo .newsintro blockquote p {
    font-size: 17px;
    line-height: 24px;
    color: #777;
    font-style: italic;
  }
}

/* Footer Top
================================================== */
.footer-top {
  background-color: #e2e2e2;
  padding: 20px 0 15px;
}

.moduletablefooterlogo {
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
}
.moduletablefooterlogo img {
  width: 80%;
  height: auto;
}
.moduletablefooterlogo p {
  margin-bottom: 0;
}

.moduletablefootermenu {
  text-align: center;
}
@media (max-width: 959px) {
  .moduletablefootermenu {
    padding: 40px 0;
    clear: both;
    display: block;
    overflow: hidden;
  }
}
.moduletablefootermenu ul {
  width: 50%;
  margin: 0 auto;
}
.moduletablefootermenu li {
  width: 50%;
  list-style: none;
  float: left;
  display: block;
  margin: 0;
}

.moduletableaccreditations {
  text-align: center;
}
.moduletableaccreditations img {
  width: 28%;
  float: left;
  margin: 2%;
}

/* Contact
================================================== */
.moduletablemap {
  padding-top: 74px;
  margin: 0 0 -5px;
}
@media (min-width: 1200px) {
  .moduletablemap {
    padding-top: 63px;
  }
}
@media (max-width: 639px) {
  .moduletablemap {
    padding-top: 149px;
  }
}

/* Product pages
================================================== */
.moduletableproductslist {
  padding: 20px;
  color: #ffffff;
  background: rgba(42, 52, 115, 0.62);
}
.moduletableproductslist p,
.moduletableproductslist li,
.moduletableproductslist a,
.moduletableproductslist h2,
.moduletableproductslist h3,
.moduletableproductslist h4 {
  color: #ffffff;
}
.moduletableproductslist a:after {
  content: "  \f35a";
  font-family: "Font Awesome 5 Pro";
}

.fitvids-container iframe {
  width: 100% !important;
  height: 354px !important;
}

/* Components
================================================== */
/* Header
================================================== */
header {
  background: #2a3473;
  height: 92px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px 0;
}
@media (max-width: 959px) {
  header {
    height: auto;
  }
}

.logo p {
  text-align: center;
  margin: 0;
}
.logo img {
  width: 400px;
  max-width: 400px;
}
@media (max-width: 959px) {
  .logo img {
    width: 420px;
  }
}

.customlogo p {
  margin: 0;
}

.sliderhead soan {
  font-weight: 800;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.moduletablectas2 {
  padding: 10px 10px 0 0;
  text-align: right;
}
@media (max-width: 959px) {
  .moduletablectas2 {
    padding: 0;
  }
}

a.brochure-link, a.quote-link {
  display: none;
}
@media (min-width: 960px) {
  a.brochure-link, a.quote-link {
    display: inline-block !important;
  }
}

a.phone-link {
  color: #ffffff;
  font-weight: 600;
  line-height: 31px;
}

a.phone-link:hover {
  color: #9ec441;
}

.social {
  display: flex;
  z-index: 50;
  justify-content: center;
}
@media (min-width: 640px) {
  .social {
    justify-content: flex-end;
  }
}
@media (min-width: 768px) {
  .social {
    position: fixed;
    right: 0;
    z-index: 50;
  }
}

.social-icons {
  padding: 16px 12px;
  color: #ffffff;
  font-size: 1.2em;
  text-align: center;
  background-color: rgba(42, 52, 115, 0.5);
}
.social-icons a {
  padding: 10px;
  color: #ffffff;
  font-size: 1.4em;
}
@media (min-width: 640px) {
  .social-icons a {
    background-color: none;
  }
}
@media (min-width: 768px) {
  .social-icons a {
    display: block;
    float: none;
  }
}
.social-icons a:hover {
  color: #9ec441;
}

.header__top {
  display: flex;
  flex-direction: column;
}
@media (min-width: 640px) {
  .header__top {
    flex-direction: row;
  }
}
.header_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 640px) {
  .header_wrap {
    justify-content: flex-end;
  }
}

/* Banner / Slider
================================================== */
/* Page Specific Styling
================================================== */
/* Sections 
================================================== */
section {
  position: relative;
  overflow: hidden;
}

/* Footer
================================================== */
footer {
  background: #aaa;
  padding: 32px 0;
  color: #707070;
}
footer p, footer li, footer a {
  color: #707070;
}
footer p {
  margin: 0;
}
footer .row {
  display: flex;
  align-items: center;
}
@media (max-width: 959px) {
  footer.__ctas {
    display: none;
  }
}

.moduletablelivebuzzlogo {
  text-align: center;
}
.moduletablelivebuzzlogo p {
  display: flex;
  justify-content: center;
  align-items: center;
}
.moduletablelivebuzzlogo img {
  width: 180px;
  height: auto;
  margin: 0 0 -9px 4px;
  opacity: 0.4;
}

a.brochure-link, a.quote-link {
  background: #FFF;
  padding: 7px 6px 6px 10px;
  border-radius: 25px;
  float: right;
  margin: 0 0 0 20px;
  color: #2a3473;
}

a.quote-link {
  background: #9ec441 !important;
}

a.quote-link:hover, a.brochure-link:hover {
  background-color: #7ba613 !important;
}

.primary-colour {
  color: #2a3473;
}

.secondary-colour {
  color: #9ec441;
}

.tertiary-colour {
  color: #616161;
}

/* Main
================================================== */
main {
  background: url("../../../images/moseley_bg.jpg") no-repeat 0% 0%;
  background-size: 100%;
}
main div.main-content {
  color: #616161;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+98 */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 98%, rgb(255, 255, 255) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 98%, rgb(255, 255, 255) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 98%, rgb(255, 255, 255) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=0 ); /* IE6-9 */
}
@media (min-width: 1800px) {
  main div.main-content {
    background: none;
  }
}

.text {
  padding-top: 60px;
  background: rgba(255, 255, 255, 0.5);
}
@media (min-width: 1800px) {
  .text {
    background: rgba(255, 255, 255, 0.2);
  }
}
@media (max-width: 639px) {
  .text {
    background: rgba(255, 255, 255, 0.7);
  }
}
.text .right {
  margin-left: auto;
}

/* Gallery
================================================== */
a.ba-filter,
a.ba-filter-active {
  padding: 7px 14px !important;
}

/* Heritage Section
================================================== */
.heritage {
  background: #2a3473 url("../../../images/heritage.jpg") no-repeat 0% 50%;
  padding: 40px 0;
  background-size: 50%;
  margin-top: 40px;
}
.heritage p,
.heritage li,
.heritage h2,
.heritage h3,
.heritage h4 {
  color: #ffffff;
}
.heritage img {
  width: 250px;
  height: auto;
  padding: 0 0 40px 20px;
  float: right;
}
@media (min-width: 1200px) {
  .heritage {
    padding: 20px 0;
  }
}
@media (max-width: 1023px) {
  .heritage {
    background-image: none;
  }
}

/* Testimonial Section
================================================== */
.testimonial {
  background: #e2e2e2 url("../../../images/quote_bg.jpg") no-repeat left bottom;
  padding: 40px 0;
  background-size: 30%;
}

.lnee ul.latestnews-items li p {
  text-align: left;
  background: none;
}

.logos,
.tagboard {
  margin-bottom: 30px;
}
.logos h3,
.tagboard h3 {
  text-align: center;
}

.tagboard {
  margin-top: 40px;
}

.lnee .newshead .picture img {
  margin-left: 40px;
}
@media (min-width: 768px) {
  .lnee .newshead .picture img {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  #lnee_100 .newshead.picturetype {
    max-width: 30% !important;
    float: right;
  }
}
@media (max-width: 639px) {
  #lnee_100 .newshead.picturetype {
    max-width: 100% !important;
    float: right;
    width: 100% !important;
  }
}

@media (max-width: 639px) {
  #lnee_100 .newsinfo {
    overflow: visible;
    padding-top: 20px;
    clear: both;
    background: none;
  }
  #lnee_100 .newsinfo .newsintro blockquote p {
    font-size: 17px;
    line-height: 24px;
    color: #777;
    font-style: italic;
  }
}

/* Footer Top
================================================== */
.footer-top {
  background-color: #e2e2e2;
  padding: 20px 0 15px;
}

.moduletablefooterlogo {
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
}
.moduletablefooterlogo img {
  width: 80%;
  height: auto;
}
.moduletablefooterlogo p {
  margin-bottom: 0;
}

.moduletablefootermenu {
  text-align: center;
}
@media (max-width: 959px) {
  .moduletablefootermenu {
    padding: 40px 0;
    clear: both;
    display: block;
    overflow: hidden;
  }
}
.moduletablefootermenu ul {
  width: 50%;
  margin: 0 auto;
}
.moduletablefootermenu li {
  width: 50%;
  list-style: none;
  float: left;
  display: block;
  margin: 0;
}

.moduletableaccreditations {
  text-align: center;
}
.moduletableaccreditations img {
  width: 28%;
  float: left;
  margin: 2%;
}

/* Contact
================================================== */
.moduletablemap {
  padding-top: 74px;
  margin: 0 0 -5px;
}
@media (min-width: 1200px) {
  .moduletablemap {
    padding-top: 63px;
  }
}
@media (max-width: 639px) {
  .moduletablemap {
    padding-top: 149px;
  }
}

/* Product pages
================================================== */
.moduletableproductslist {
  padding: 20px;
  color: #ffffff;
  background: rgba(42, 52, 115, 0.62);
}
.moduletableproductslist p,
.moduletableproductslist li,
.moduletableproductslist a,
.moduletableproductslist h2,
.moduletableproductslist h3,
.moduletableproductslist h4 {
  color: #ffffff;
}
.moduletableproductslist a:after {
  content: "  \f35a";
  font-family: "Font Awesome 5 Pro";
}

.fitvids-container iframe {
  width: 100% !important;
  height: 354px !important;
}

/* Navigation
================================================== */
.nav--main {
  display: none;
  justify-content: flex-end;
}
@media (min-width: 960px) {
  .nav--main {
    display: flex;
  }
}
.nav--main .nav {
  width: 100%;
  background: none;
}
@media (min-width: 960px) {
  .nav--main .nav {
    display: flex;
    justify-content: flex-end;
    margin: 0;
  }
}
.nav--main .nav li {
  position: relative;
  text-align: center;
  list-style: none;
  left: 0;
}
@media (max-width: 959px) {
  .nav--main .nav li {
    width: 100%;
    display: flex;
  }
}
.nav--main .nav a {
  display: block;
  padding: 11px 11px;
  color: #ffffff;
  font-size: 1em;
  text-decoration: none;
  line-height: 120%;
}
@media (min-width: 960px) {
  .nav--main .nav a {
    padding: 11px 6px;
    font-size: 0.9em;
  }
}
@media (min-width: 1200px) {
  .nav--main .nav a {
    padding: 11px 11px;
    font-size: 1em;
  }
}
.nav--main .nav a:hover, .nav--main .nav .current > a, .nav--main .nav .active > a {
  color: #9ec441;
}
.nav--main .nav i {
  margin-left: 5px;
}
.nav--main .nav ul {
  display: none;
  width: 100%;
  position: static;
  margin: 0;
  font-size: 0.8em;
}
@media (min-width: 960px) {
  .nav--main .nav ul {
    position: absolute;
    width: auto;
    top: 50px;
    left: 0;
    z-index: 9;
    font-size: 1em;
    background-color: #2a3473;
  }
}
@media (max-width: 959px) {
  .nav--main .nav ul.active {
    display: block !important;
  }
}
.nav--main .nav ul li {
  width: 100%;
  margin: 0;
}
@media (min-width: 960px) {
  .nav--main .nav li:hover > ul {
    display: block;
  }
}
.nav--main .nav > * {
  overflow: visable;
}
@media (max-width: 959px) {
  .nav--main .nav.active, .nav--main .nav > ul.active {
    display: block;
  }
  .nav--main .nav.active a {
    display: block;
    padding: 0.6em;
  }
  .nav--main .nav.active li, .nav--main .nav.active ul ul {
    width: 100%;
    height: auto;
    float: left;
    display: block;
    font-size: 1em !important;
  }
  .nav--main .nav.active ul ul {
    display: block;
    position: inherit;
    top: auto;
  }
}

.nav--mobile {
  display: none;
}
.nav-active .nav--mobile {
  display: flex;
}
.nav--mobile .nav {
  padding: 0;
}
.nav--mobile .nav li {
  justify-content: center;
}

/*nav.nav--main.active ul {
  @include bp_max(large) {
    display:block !important;
  }
}*/
.menu-link {
  height: 33px;
  position: relative;
  z-index: 1;
  display: inline;
  cursor: pointer;
  margin: 10px 20px;
  float: right;
  background: none;
}
.menu-link:hover {
  background-color: none;
  background: none;
}
.menu-link span, .menu-link span:before, .menu-link span:after {
  width: 35px;
  height: 5px;
  position: absolute;
  top: 15px;
  display: block;
  content: "";
  background: #ffffff;
  border-radius: 1px;
  transition: all 500ms ease-in-out;
}
.menu-link span:before {
  top: -10px;
}
.menu-link span:after {
  top: auto;
  bottom: -10px;
}
.menu-link.active span {
  background: transparent;
}
.menu-link.active span:before, .menu-link.active span:after {
  top: 0;
  background: #9ec441;
  background-color: none;
}
.menu-link.active span:before {
  rotate: 45deg;
}
.menu-link.active span:after {
  rotate: -45deg;
}
@media (min-width: 960px) {
  .menu-link {
    display: none;
  }
}