/* Navigation
================================================== */
.nav--main {
  display: none;
  justify-content:flex-end;

  @include bp(large){
    display: flex;
  }
  .nav {
    width: 100%;
    background: none;
    
    @include bp(large){
      display: flex;
      justify-content:flex-end;
      margin:0;
      
    }

      li {
        //float: right;
        position: relative;
        text-align: center;
        list-style: none;
        left: 0;
        
        @include bp_max(large) {
          width:100%; 
          display:flex;
        }
      }

      a {
        display: block;
        padding: 11px 11px;
        color: $white;
        font-size: 1em;
        text-decoration: none;
        line-height: 120%;
      
        @include bp(large){
          padding: 11px 6px;
          font-size: 0.9em;
        }
        @include bp(xxlarge){
          padding: 11px 11px;
          font-size: 1em;
        }
        }

    a:hover, .current > a, .active > a {
      color: $secondary-colour;
    }
    i {
      margin-left: 5px;
    }
    ul {
      display: none;
      width: 100%;
      //float: left;
      position: static;
      margin: 0;
      font-size: 0.8em;
      @include bp(large){
        position: absolute;
        //min-width: 150px;
        width: auto;
        top: 50px;
        left: 0;
        z-index: 9;
        font-size: 1em;
        background-color: $primary-colour;
      }
      
      &.active {
        @include bp_max(large) {
          display: block !important;
        }
      }
      li {
        width: 100%; margin:0;
      }
    }
    li:hover > ul {
      @include bp(large){
        display: block;
      }
    }
    & > * {
      overflow: visable;
    }

    @include bp_max(large) {
      &.active, & > ul.active {
          display: block;
      }
      &.active a {
          display: block;
          padding: 0.6em;
      }
      &.active li, &.active ul ul {
          width: 100%;
          height: auto;
          float: left;
          display: block;
          font-size: 1em !important;
      }
      &.active ul ul {
          display: block;
          position: inherit;
          top: auto;
      }
    }
  }
}

.nav {
  &--mobile {
    display: none;
    .nav-active & {
      display: flex;
    }
    .nav {
      padding: 0;
      li {
        justify-content: center;
      }
    }
  }
}

/*nav.nav--main.active ul {
  @include bp_max(large) {
    display:block !important;
  }
}*/

.menu-link {
    height: 33px;
    position: relative;
    z-index: 1;
    display: inline;
    cursor: pointer;
    margin: 10px 20px;
    float: right;
    background:none;

    &:hover {
      background-color:none;
      background:none;
    }

    

    span, span:before, span:after {
      width: 35px;
      height: 5px;
      position: absolute;
      top: 15px;
      display: block;
      content: '';
      background: $white;
      border-radius:1px;
      transition:all 500ms ease-in-out;
    }

    span {
      &:before {
        top: -10px;
      }
      &:after {
        top: auto;
        bottom: -10px;
      }
    }

    &.active {
      span {
        background: transparent;
        &:before, &:after {
          top: 0;
          background: $secondary-colour;
          background-color:none;
        }
        &:before {
          rotate:(45deg);
        }
        &:after {
          rotate:(-45deg);
        }
      }
    }

    @include bp(large){
      display: none;
    }
}